define("discourse/plugins/discourse-private-replies/discourse/initializers/extend-for-private-replies", ["exports", "discourse/lib/plugin-api", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _pluginApi, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function registerTopicFooterButtons(api) {
    api.registerTopicFooterButton({
      id: "privatereplies",
      icon: function icon() {
        var isPrivate = this.get("topic.private_replies");
        return isPrivate ? "far-eye" : "far-eye-slash";
      },
      priority: 250,
      title: function title() {
        var isPrivate = this.get("topic.private_replies");
        return "private_replies.button.".concat(isPrivate ? "public_replies" : "private_replies", ".help");
      },
      label: function label() {
        var isPrivate = this.get("topic.private_replies");
        return "private_replies.button.".concat(isPrivate ? "public_replies" : "private_replies", ".button");
      },
      action: function action() {
        var _this = this;

        if (!this.get("topic.user_id")) {
          return;
        }

        var action;

        if (this.get("topic.private_replies")) {
          action = 'disable';
        } else {
          action = 'enable';
        }

        return (0, _ajax.ajax)('/private_replies/' + action + '.json', {
          type: "PUT",
          data: {
            topic_id: this.get("topic.id")
          }
        }).then(function (result) {
          _this.set("topic.private_replies", result.private_replies_enabled);
        }).catch(_ajaxError.popupAjaxError);
      },
      dropdown: function dropdown() {
        return this.site.mobileView;
      },
      classNames: ["private-replies"],
      dependentKeys: ["topic.private_replies"],
      displayed: function displayed() {
        var topic_owner_id = this.get("topic.user_id");
        return this.currentUser && (this.currentUser.id == topic_owner_id || this.currentUser.staff);
      }
    });
  }

  var _default = {
    name: "extend-for-privatereplies",
    initialize: function initialize(container) {
      var siteSettings = container.lookup("site-settings:main");

      if (!siteSettings.private_replies_enabled) {
        return;
      }

      (0, _pluginApi.withPluginApi)("0.8.28", function (api) {
        return registerTopicFooterButtons(api, container);
      });
    }
  };
  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/connectors/topic-above-posts/privatereplies"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"model\",\"private_replies\"]]],null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"post-notice custom\"],[8],[0,\"\\n    \"],[1,[28,\"d-icon\",[\"user-secret\"],null],false],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[7,\"p\",true],[8],[1,[28,\"i18n\",[\"private_replies.topic_banner_line_1\"],null],false],[9],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"siteSettings\",\"private_replies_topic_starter_primary_group_can_see_all\"]],true],null]],null,{\"statements\":[[0,\"        \"],[7,\"p\",true],[8],[1,[28,\"i18n\",[\"private_replies.topic_banner_line_2_also_group\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"p\",true],[8],[1,[28,\"i18n\",[\"private_replies.topic_banner_line_2\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/connectors/topic-above-posts/privatereplies"}});

